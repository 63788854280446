.inputlabel {
  padding: 0px 20px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.inputcurcnt {
  background-color: #f4f4f4;
  border-radius: 6px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0px 20px 10px ;
}

.inputcurrencyinput {
    width: 100%;
    margin: 0px 0px 0px 20px;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    font-size: 15px;
    line-height: 25px;
    color: #111;
}

.inputcurrencyinput::placeholder {
    color: #111;
    font-family: 'Barlow', sans-serif;
}

.inputcurrencycardcnt {
    background-color: #0da5a5;
    height: 50px;
    border-radius: 0px 6px 6px 0px;
    padding: 13px 16px 13px 16px;
    cursor: pointer;
    
}

.inryimage {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.62px;
    color: #fff;
}

.inputcurrencylist {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 51;
    background-color: #fff;
}

.inputcurrencylistcnt {
    padding: 0px 20px 20px 20px;
}