.otpcnt {
  display: flex;
  width: 100%;
  max-width: 300px;
  column-gap: 10px;
  padding: 20px 0px;
}

.otpinput {
  width: 100%;
  height: 56px;
  border-radius: 6px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  border: 1px solid #ebe9e9;
  color: #999999;
}

.otpinput::placeholder {
    color: #999999;
}
