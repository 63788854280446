.activesubcnt {
  padding: 25px 16px;
  border-radius: 6px;
  background-color: #0da5a5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  margin-bottom: 20px;
}

.activesubcntleft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.activesubdel {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.actsubsname {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}

.actdate {
  font-weight: 400;
  font-size: 10px;
  line-height: 13.02px;
  letter-spacing: 0.4px;
  color: #fff;
  margin-top: 3px;
}

.activesubcntamount {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  color: #fff;
}

.billsactivesubbody {
    padding: 0px 20px;
}