
  .settransferpincnt {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .settransferpincnt img {
    width: 80px;
    object-fit: contain;
    object-position: center;
    padding: 20px 15px;
  }

  .settransferpincntdivider {
    margin-top: 30px;
    width: 100%;
    height: 1px;
    background-color: #ebe9e9;
  }

  .settransferconfirmbtn {
    width: 100%;
    height: 26px;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-weight: 400;
    font-size: 20px;
    line-height: 26.04px;
    letter-spacing: -0.3px;
    color: #0da5a5;
    padding: 30px;
    cursor: pointer;
  }

