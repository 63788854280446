.billsheader {
  padding: 30px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.04px;
  letter-spacing: -0.3px;
  text-align: center;
}

.billitems {
  padding: 0px 20px 20px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.billitem {
  height: 78px;
  background: #0da5a533;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billitemname {
  font-size: 10px;
  line-height: 13.02px;
  letter-spacing: -0.3px;
  font-weight: 500;
  text-align: center;
}

.billsactive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
}

.billsactive h3 {
  font-size: 12px;
  font-weight: 700;
  line-height: 15.62px;
  letter-spacing: 0.5px;
}

.billsactiveright {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.billsactiveright label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15.62px;
    letter-spacing: .5px;
}