
  .appheadercnt {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    align-items: center;
  }

  .appheadercnt h2 {
    font-size: 20px;
    font-weight: 700;
  }
