
  .alreadysigncnt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 15px;
  }

  .alreadysigncnt p {
    font-weight: 400;
    font-size: 12px;
  }

  .alreadysigncnt span {
    font-weight: 400;
    cursor: pointer;
    color: #0da5a5;
    padding-left: 10px;
  }

