.cryptopagetop {
  padding: 0px 20px 20px 20px;
}

.cryptomainwalletcard {
  height: 115px;
  background: #0da5a5;
  box-shadow: 0px 15px 30px 0px #0054500d;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.cryptomainwalletcard h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
}

.cryptomainwalletcard p {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.62px;
  color: #ffffff80;
}

.cryptocointitle {
    padding: 0 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
}
