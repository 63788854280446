.actionpagebody {
  padding: 10px 20px;
}

.actionpagebodyitems {
  margin-bottom: 20px;
  border: 1px solid #ebe9e9;
  border-radius: 6px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionpagebodyitemsleft {
  cursor: pointer;
}

.actionpagebodyitemsright h2 {
  color: #0da5a5;
  font-weight: 700;
  font-size: 20px;
  line-height: 26.04px;
  letter-spacing: -0.3px;
  margin-bottom: 5px;
}

.actionpagebodyitemsright p {
  font-size: 12px;
  line-height: 15.62px;
  letter-spacing: 0.5px;
  font-weight: 400;
}
