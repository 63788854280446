 @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap'); */


:root {
  --productvalue: 0%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  box-sizing: inherit;
  /* font-family: 'Barlow', sans-serif; */
  font-family: "Plus Jakarta Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  position: relative;
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
}

code {
   /* font-family: 'Barlow', sans-serif; */
   font-family: "Plus Jakarta Sans",sans-serif;
}

input,
select {
  font-size: 100%;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

.text__active {
  color: #0184fb;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.03em;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.text__not-active {
  color: #53575e;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.03em;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.ddiverder {
  border-bottom: 1px solid #9a9fbf15;
}

.diffbottomna {
  border-bottom: 0.1px solid rgba(63, 62, 62, 0.315);
}

/* #DA342A;
#AD2089;
#9B62FA;
#826B02;
#0D1C63;
#4B75BF;
#143424;
#6F5345;
#367284;
#443226;
#675D8B;
#172C86;
#991B1A;
#4A2118;
#304B66;
#954A21;
#8B6B49; */
