.homecnt {
  background: linear-gradient(180deg, #0da5a5 0%, #0d5ca5 63.02%);
  width: 100%;
}

.homecnt-top {
  width: 100%;
  display: flex;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.homecnt-topbtn {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 5px 10px;
  border-radius: 20px;
  border: none;
  background-color: #fff;
  font-family: inherit;
  font-weight: 700;
  font-size: 12px;
  line-height: 25px;
  color: #0da5a5;
  cursor: pointer;
}

.homecntsec1,
.homecntsec2 {
  padding: 0px 20px 30px 20px;
}

.homecntsec1title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
}

.homecntsec2title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15.62px;
  color: #90ebeb;
}

.homecntsec2amt {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #fff;
}

.homecntsec2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homecntsec3 {
  background-color: #fff;
  padding: 50px 0px;
  border-radius: 20px 20px 0px 0px;
}

.homecntsec3nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
}

.homecntsec3nav button {
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.62px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.homecntbtnactive {
  border-bottom: 1px solid #0d5ca5 !important;
  color: #111;
}

.homecntbtnnotactive {
  border-bottom: 1px solid #99999933 !important;
  color: #999999;
}

.homesndmoneytop {
  background-color: #0da5a510;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: end;
  gap: 20px;
  margin: 0px 20px 20px;
}

.hmsndmtlefttop {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.hmsndmtwarn {
  background-color: #0da5a5;
  padding: 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  cursor: pointer;
}

.hmsndmtsa {
  display: grid;
  grid-gap: 10px;
}

.hmsndmttitle,
.hmsndmtparagraph {
  font-weight: 400;
  font-size: 12px;
  line-height: 15.62px;
  letter-spacing: 0.4px;
}

.hmsndmtparagraph {
  color: #aeb6ce;
}

.hmsndmttitle {
  font-weight: 600;
  color: #111;
}

.hmsndverify {
  height: 30px;
  padding: 7px 26px;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.62px;
  letter-spacing: 0.4px;
  color: #0da5a5;
  cursor: pointer;
}

.hometransactiontop {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px 20px;
  height: 49px;
  background-color: #f4f4f4;
  padding: 12px 16px;
  border-radius: 8px;
  color: #999999;
}

.htrancinput {
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  outline: none;
}

.hometranclist {
  padding: 0px 20px;
}

.hmtransitem {
  display: flex;
  align-items: flex-flex-start;
  justify-content: space-between;
  border-bottom: 0.5px solid #0da5a5;
  padding: 10px 0;
  padding-top: 20px;
  cursor: pointer;
}

.hmtransitemleft {
  display: flex;
  gap: 10px;
  align-items: flex-flex-start;
}

.transuc {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #29a50d;
  margin-top: 3px;
}

.transfail {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #ff0303;
  margin-top: 3px;
}

.hmname {
  font-size: 12px;
  font-weight: 600;
  line-height: 15.62px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}

.trasuc,
.trafail {
  font-size: 8px;
  font-weight: 500;
  line-height: 10.42px;
  letter-spacing: 0.4px;
  padding: 0px 8px;
  justify-self: flex-flex-start;
  width: min-content;
  border-radius: 3px;
}

.trasuc {
  background: #29a50d1a;
  color: #0da5a5;
}

.trafail {
  background: #ff03031a;
  color: #ff0303;
}

.hmtransitemright {
  text-align: right;
}

.hmrprice {
  font-weight: 600;
  font-size: 12px;
  line-height: 15.62px;
  letter-spacing: 0.4px;
  padding-bottom: 5px;
}

.hmrpricedol {
  font-size: 8px;
  font-weight: 500;
  line-height: 10.42px;
  letter-spacing: 0.4px;
  color: #999999;
}
/* background: linear-gradient(180deg, #0DA5A5 0%, #0D5CA5 63.02%); */

.thesawap {
  padding: 10px 20px 20px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.thesawapp {
  padding: 10px 20px 20px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.thesawapbtn {
  padding: 2px 10px 2px 10px;
  border-radius: 6px;
  gap: 10px;
  background-color: #0da5a5;
  border: none;
  color: #fff;
  height: 20px;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 15.62px;
  cursor: pointer;
}


.thesawaprate {
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;

}

.thesawapprice {
  color: #A09191CC;
  font-weight: 500;
  font-size: 12px;
  line-height: 15.62px;

}