.registeragree {
  display: none;
}

@media screen and (max-width: 500px) {
  .registeragree {
    display: flex;
    padding: 20px;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.35px;
    color: #a4a3a3;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.intl-tel-inddput {
  width: 100% !important;
}