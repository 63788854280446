
  .inputfieldcnt {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 20px 20px 20px;
  }

  .inputfieldcnt label {
    font-size: 12px;
    font-weight: 400;
    font-family: inherit;
  }

  .inputfieldcnt input {
    height: 50px;
    background-color: #f4f4f4;
    border: none;
    outline: none;
    font-size: 15px;
    font-family: inherit;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px;
  }

  .inputfieldcnt select {
    height: 50px;
    background-color: #f4f4f4;
    border: none;
    outline: none;
    font-size: 15px;
    font-family: inherit;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px;
  }

