.curencycardcnt {
  border: 1px solid #ffffff;
  padding: 12px, 16px, 12px, 16px;
  border-radius: 6px;
  cursor: pointer;
  justify-self: flex-end;
  background: linear-gradient(0deg, #20cbcb, #20cbcb),
    linear-gradient(0deg, #ffffff, #ffffff);
    width: 96px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cryimage {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  line-height: 15.62px;
  font-size: 12px;
}

.currencycardlist {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 50;
}

.currencycardlistcnt {
  padding: 0px 20px 20px 20px;
}

.crclstcnt {
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crclstcntleft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.crclsname, .crclstcntright {
  font-weight: 500;
  font-size: 15px;
  line-height: 19.53px;
  letter-spacing: .5px;
}