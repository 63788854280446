.signinalert {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 120px 15px 30px 15px;
}

.signinalert img {
  width: 64px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 30px;
}

.signinalert h2 {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 26.04px;
}

.signinalert p {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 19.53px;
}


.registeragree {
  display: none;
}

@media screen and (max-width: 500px) {
  .registeragree {
    display: flex;
    padding: 20px;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.35px;
    color: #a4a3a3;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
