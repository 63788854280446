
  .authBtncnt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 20px;
  }

  .authBtncnt button {
    height: 50px;
    width: 100%;
    border: none;
    border-radius: 6px;
    background-color: #0da5a5;
    font-family: inherit;
    font-weight: 500;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
  }

