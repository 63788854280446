.sucrec {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0px 20px 10px 20px;
}

.tsuc {
  padding: 0px 10px;
  background-color: #29a50d1a;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.02px;
  letter-spacing: 0.4px;
  color: #29a50d;
}

.trec {
  display: flex;
  align-items: center;
  gap: 5px;
}

.trec label {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.transdetails {
  margin: 0px 20px 20px 20px;
  padding: 20px;
  display: grid;
  grid-gap: 10px;
  background-color: #0da5a51a;
  border-radius: 8px;
}

.transdetailsitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transdetailsitem p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15.62px;
  letter-spacing: 0.4px;
}

.transtime {
  font-size: 15px;
  line-height: 19.53px;
  letter-spacing: 0.4px;
  font-weight: 500;
  padding: 0 20px 10px 20px;
}

.transref {
  margin: 0px 20px 20px 20px;
  padding: 16px;
  border: 1px solid #ebe9e9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transrefnum {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.62px;
  letter-spacing: 0.43px;
}

.transferno {
  padding-top: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.23px;
  letter-spacing: 0.4px;
}

.trsentmny {
  padding: 0px 20px 10px 20px;
}

.trsentmny label {
  font-size: 15px;
  font-weight: 500;
  line-height: 19.53px;
  letter-spacing: .43px;
  color: #999999;
}

.trsenttext {
  border: 1px solid #EBE9E9;
  width: 100%;
  height: 60px;
  padding: 20px;
  margin-top: 5px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.83px;
  letter-spacing: .4px;
}