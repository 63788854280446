.appheaderonecnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
}

.appheaderlabel {
    font-weight: 700;
    font-size: 20px;
    line-height: 26.04px;
    letter-spacing: .3px;
    text-align: center;
}

.appheaderonecnt button {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

